<template>
  <div class="refund-container">
    <template v-if="showResult">
      <div class="card-info">
        <coupon-card-v1 :card="basicInfo"></coupon-card-v1>
      </div>
      <div class="refund-reason">
        <div class="card_title">退款信息</div>
        <cell-v3 :hide-icon="true" class="card-cell">
          <div slot="title" class="cell-title">退款原因</div>
          <div class="cell-right" @click="showReasonList">
            {{ reasonText }}
            <img
              src="https://api.mengjingloulan.com/storage/wechat/new-user-center/back.png"
            />
          </div>
        </cell-v3>
        <cell-v3 :hide-icon="true" class="card-cell">
          <div slot="title" class="cell-title">退款金额</div>
          <div class="cell-right money">¥{{ basicInfo.pay_amount }}</div>
        </cell-v3>
      </div>
      <div class="refund-actions">
        <div class="card_title">详细原因</div>
        <div class="reasons">
          <nut-textbox
            v-if="refundInfo.id == 0"
            :txt-areaH="10"
            :place-text="'请补充详细维权原因，有助于更好的处理问题（200字内）'"
            :switch-max="true"
            :disabled="refundInfo.id != 0"
            :max-num="200"
            v-model="refundInfo.content"
            text-bgColor="#f6f6f6"
            :limit-show="false"
          >
          </nut-textbox>
          <div v-else class="box-p-10 refund-content">
            {{ refundInfo.content }}
          </div>
          <div class="box-m-t-10" v-if="refundInfo.id == 0">
            <upload-img-v1
              :max="5"
              :width="60"
              :height="60"
              :margin="2"
              :disabled="refundInfo.id != 0"
              :imgList.sync="refundInfo.refund_images"
              apiUrl="https://community-mall.mengjingloulan.com/file-upload"
              :autoUpload="true"
              @clickImg="previewImg"
            >
            </upload-img-v1>
          </div>
        </div>
      </div>

      <div
        class="refund-actions"
        v-if="refundInfo.id > 0 && refundInfo.status_name"
      >
        <div class="card_title">退款进度</div>
        <div class="reasons">
          <div class="box-p-10 refund-content">
            {{ refundInfo.status_name }}
          </div>
        </div>
      </div>

      <div
        class="refund-actions"
        v-if="refundInfo.id > 0 && refundInfo.cant_return_reason"
      >
        <div class="card_title">不退款原因</div>
        <div class="reasons">
          <div class="box-p-10 refund-content">
            {{ refundInfo.status_name }}
          </div>
        </div>
      </div>

      <nut-actionsheet
        :is-visible="isVisible"
        @close="switchActionSheet"
        :menu-items="reasonList"
        @choose="chooseItem"
        cancelTxt="取消"
      ></nut-actionsheet>

      <div class="detail-buttons" v-if="refundInfo.id == 0">
        <div class="buttons">
          <div class="use" @click="handleRefundApply">提交</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CellV2 from "@/template/community/cell/cellV2";
import CouponCardV1 from "@/template/coupon-card/coupon-card-v1";
import CellV3 from "@/template/community/cell/cellV3";
import UploadImgV1 from "@/template/community/uploadImg/uploadImgV1";

export default {
  name: "refund",
  components: { UploadImgV1, CellV3, CouponCardV1, CellV2 },
  data() {
    return {
      isVisible: false,
      card_id: 0,
      basicInfo: {},
      refundInfo: {
        id: 0, //大于零提交过退款
        content: "", //退款详细原因
        order_id: 0, //订单id
        return_exchange: 0, //是否可以退款 0可退换 1不可退换
        cover: "", //商品图片
        title: "", //商品名称
        goods_amount: "", //商品价格
        total: 800, //数量
        refund_images: [], //退款图片
        reason: [], //退款原因
        status_name: "", //退款进度
        cant_return_reason: "", //不退还原因
      },
      baseReasons: [], //用于校验的原因列表
      reasonList: [], //退款原因列表
      reasonText: "请选择退款原因",
      showResult: false,
      isLoading: false,
      canContinue: false,
    };
  },
  mounted() {
    document.title = "退款申请";
    let query = this.$route.query;
    this.card_id = query.card_id || 0;
    this.getRefundReason();
    this.getRefundInfo();
    this.canContinue = true;
  },
  computed: {
    reasonChecked() {
      // let item = this.refundInfo.reason.find((val) => val.selected == 1)
      // if (item) {
      //   return item?.title
      // }
      // return '请选择退款原因'
    },
  },
  methods: {
    getRefundReason() {
      this.$api.couponCard.getRefundReasonList().then((response) => {
        let reasonList = [];
        response.data.forEach((val) => {
          reasonList.push({
            name: val.title,
            value: val.title,
          });
          this.baseReasons.push(val.title);
        });
        this.reasonList = reasonList;
      });
    },
    getRefundInfo() {
      this.$api.couponCard
        .getRefundInfo({ card_id: this.card_id })
        .then((response) => {
          this.basicInfo = response.data.basicInfo;
          if (typeof response.data.refundInfo.id != "undefined") {
            this.refundInfo = response.data.refundInfo;
            this.reasonText = response.data.refundInfo.refund_reson;
            this.refundInfo.content = response.data.refundInfo.refund_content;
          }
          console.log("this.refundInfo", this.refundInfo);
          this.showResult = true;
        });
    },
    previewImg(e) {
      let item = null;

      let index = 0;
      this.refundInfo.refund_images.map((val, idx) => {
        if (val.id == e.msg) {
          item = val;
          index = idx;
        }
      });
      this.$previewImg({
        imgUrl: item.path,
        showCancel: this.refundInfo.id == 0,
        success: () => {},
        fail: () => {
          let list = this.refundInfo.refund_images;
          list.splice(index, 1);
          this.refundInfo.refund_images = list;
        },
      });
    },
    showReasonList() {
      this.isVisible = true;
    },
    switchActionSheet() {
      this.isVisible = !this.isVisible;
    },
    chooseItem(e) {
      this.reasonText = e.value;
    },
    handleRefundApply() {
      let data = {
        card_id: this.card_id,
        cover_images: this.refundInfo.refund_images
          .map((val) => val.id)
          .join(","),
        refund_content: this.refundInfo.content,
        refund_reson: this.reasonText,
      };

      if (!this.baseReasons.includes(data.refund_reson)) {
        this.$toast.warn("请选择退货原因");
        return;
      }

      if (!this.isLoading) {
        this.$api.couponCard
          .handleRefundApply(data)
          .then((response) => {
            this.$toast.success("申请成功");
            this.getRefundInfo();
            this.isLoading = false;
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.refund-container {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .card-info {
    width: 100%;
    background: #fff;
  }

  .refund-reason {
    background: #fff;
    padding: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    .card_title {
      color: #333333;
      font-size: 14px;
      font-weight: bold;
    }

    .card-cell {
      margin-top: 20px;

      .cell-title {
        font-size: 14px;
        color: #a2a2a2;
      }

      .cell-right {
        font-size: 14px;
        color: #a2a2a2;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          width: 9px;
          height: 9px;
          margin-left: 10px;
        }
      }

      .money {
        color: #f46a17;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .refund-actions {
    background: #fff;
    padding: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-top: 10px;

    .card_title {
      color: #333333;
      font-size: 14px;
      font-weight: bold;
    }

    .reasons {
      margin-top: 20px;
      background: #f6f6f6;
      border-radius: 8px;
      padding: 15px;

      /deep/ .txt-area {
        background: #f6f6f6 !important;
        border: none;
        padding: 0;
      }
      /deep/ .nut-textbox > .txt-area > textarea {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #aaa;
        height: 50px !important;
        &::placeholder {
          color: #a2a2a2;
          font-size: 12px;
        }
      }
    }
  }

  .detail-buttons {
    width: 100%;

    .buttons {
      display: flex;
      padding: 15px;

      .use {
        width: 336px;
        height: 44px;
        background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.refund-content {
  font-size: 14px;
  color: #a2a2a2;
}
</style>
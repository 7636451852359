<template>
    <div class="nut-imagepicker">
        <div class="img-list">
            <div
                    class="img-item"
                    v-for="(item,index) in list"
                    :key="item.id"
                    @click="clickImg(item.id)"
                    @touchstart="
            delMode == 'longtap' && !disabled ? touchStart(item.id) : ''
          "
                    @touchmove="
            delMode == 'longtap' && !disabled ? touchMove(item.id) : ''
          "
                    @touchend="delMode == 'longtap' && !disabled ? touchEnd(item.id) : ''"
            >
                <a href="javascript:;"><img :src="item.path" alt=""/></a>
                <img v-if="canRemove" @click="removeImage(index)" class="remove-img"
                     src="https://api.mengjingloulan.com/storage/wechat/services/remove-image.png"/>
            </div>
            <div class="add-icon" v-show="list.length < max && !disabled">
                <div>
                    <img :src="require('@STATIC/image/icons/camera.png')"/>
                    <span>{{ list.length }}/{{ max }}</span>
                </div>
                <input
                        type="file"
                        name="files"
                        :multiple="ismultiple ? 'multiple' : ''"
                        :accept="accept"
                        @change="addImg"
                />
            </div>
        </div>
    </div>
</template>
<script>
  import axios from 'axios'
  import store from '@/store'

  export default {
    name: 'uploadImgV1',
    props: {
      //是否禁用
      disabled: {
        type: Boolean,
        default: false,
      },
      apiUrl: {
        type: String,
        default: null,
      },
      animation: {
        type: [Boolean],
        default: true,
      },
      max: {
        //允许上传的最大数量
        type: [Number, String],
        default: 9,
      },
      accept: {
        type: [String],
        default: 'image/*',
      },
      ismultiple: {
        //是否开启多图片上传
        type: [Boolean],
        default: true,
      },
      delMode: {
        //删除图片的方式，支持tap、longtap
        type: [String],
        default: 'tap',
      },
      longTapTime: {
        type: [Number],
        default: 500,
      },
      autoUpload: {
        //选择完图片之后是否自动完成上传
        type: [Boolean, String],
        default: false,
      },
      imgList: {
        type: Array,
        default () {
          return []
        },
      },
      canRemove: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        timeOutEvent: 0,
        list: [],
      }
    },
    watch: {
      imgList: function (val) {
        this.list = val
      },
    },
    mounted () {
      this.list = this.imgList
    },
    methods: {
      addImg (event) {
        let self = this
        //限制图片上传数量
        let file = event.target.files

        let fileArr = Array.from(file)

        if (file.length > self.max - self.list.length) {
          fileArr = fileArr.filter(
            (item, index) => index < self.max - self.list.length
          )
        }

        if (self.autoUpload) {
          //自动上传
          // self.$emit("imgMsg", {
          //   code: 1,
          //   msg: fileArr,
          // });
          // self.$emit("img-msg", {
          //   code: 1,
          //   msg: fileArr,
          // });
          fileArr.forEach((item, index) => {
            self.upload(item).then((val) => {
              let info = val.data.data
              self.list.push(info)
            })
          })
        } else {
          fileArr.forEach((item, index) => {
            let reader = new FileReader()
            reader.onload = function (evt) {
              self.list.push({
                id: Math.random(),
                src: evt.target.result,
              })
              event.target.value = ''
            }
            reader.readAsDataURL(item)
          })
          self.$emit('imgMsg', {
            code: 2,
            msg: fileArr,
          })
          self.$emit('img-msg', {
            code: 2,
            msg: fileArr,
          })
        }
      },
      clickImg (id) {
        this.$emit('clickImg', {
          code: 4,
          msg: id,
        })
        this.$emit('click-img', {
          code: 4,
          msg: id,
        })
      },
      // deleteImg(id) {
      //   this.list = this.list.filter((item) => item.id != id);
      //   this.$emit("update:imgList", this.list);
      //   this.$emit("clickImg", {
      //     code: 3,
      //     msg: id,
      //   });
      //   this.$emit("click-img", {
      //     code: 3,
      //     msg: id,
      //   });
      // },
      touchStart (id) {
        // console.log(this.longTapTime);
        this.timeOutEvent = setTimeout(() => {
          this.clickImg(id)
        }, this.longTapTime)
        return false
      },
      touchMove (id) {
        clearTimeout(this.timeOutEvent)
        return false
      },
      touchEnd (id) {
        clearTimeout(this.timeOutEvent) //清除定时器
        this.timeOutEvent = 0
      },
      upload (file) {
        return new Promise((resolve, reject) => {
          let param = new FormData() // 创建form对象
          param.append('file', file) // 通过append向form对象添加数据
          param.append('chunk', '0') // 添加form表单中其他数据

          let config = {
            headers: {'Content-Type': 'multipart/form-data'},
          }
          Object.assign(config.headers, store.state.common_platform.header)
          // 添加请求头
          axios
            .post(this.$props.apiUrl, param, config)
            .then((res) => {
              this.$emit('onFileUpload')
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      },
      removeImage (index) {
        this.list.splice(index, 1)
      }
    },
  }
</script>
<style lang="scss" scoped>
    .img-list {
        display: inline-flex;
        & > span {
            display: flex;
        }
    }

    .img-item {
        width: 60px;
        height: 60px;
        margin: 0 !important;
        margin-right: 6px !important;
        position: relative;
        & > a > img {
            border-radius: none !important;
        }

        .remove-img {
            width: 12px;
            height: 12px;
            position: absolute;
            right: -3px;
            top: -3px;
        }
    }

    .add-icon {
        width: 60px;
        height: 60px;
        border-color: #d1d1d1 !important;
        border-radius: 0 !important;
        & > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: #d1d1d1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            & > img {
                width: 16px;
                height: 16px;
            }
            & > span {
                margin-top: 5px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #d1d1d1;
            }
        }
    }
</style>

import { render, staticRenderFns } from "./uploadImgV1.vue?vue&type=template&id=58fe05a3&scoped=true&"
import script from "./uploadImgV1.vue?vue&type=script&lang=js&"
export * from "./uploadImgV1.vue?vue&type=script&lang=js&"
import style0 from "./uploadImgV1.vue?vue&type=style&index=0&id=58fe05a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58fe05a3",
  null
  
)

export default component.exports